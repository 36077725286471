import { i18n } from "@/plugins/i18n";

export default [
  {
    text: i18n.t("blood_type.o_rh_negative"),
    value: "O_RH_NEGATIVE"
  },
  {
    text: i18n.t("blood_type.o_rh_positive"),
    value: "O_RH_POSITIVE"
  },
  {
    text: i18n.t("blood_type.a_rh_negative"),
    value: "A_RH_NEGATIVE"
  },
  {
    text: i18n.t("blood_type.a_rh_positive"),
    value: "A_RH_POSITIVE"
  },
  {
    text: i18n.t("blood_type.b_rh_negative"),
    value: "B_RH_NEGATIVE"
  },
  {
    text: i18n.t("blood_type.b_rh_positive"),
    value: "B_RH_POSITIVE"
  },
  {
    text: i18n.t("blood_type.ab_rh_negative"),
    value: "AB_RH_NEGATIVE"
  },
  {
    text: i18n.t("blood_type.ab_rh_positive"),
    value: "AB_RH_POSITIVE"
  }
];
